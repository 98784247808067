import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { useResponsive } from 'imports/core/api/responsiveContext';
import Flex from 'imports/core/ui/atoms/Flex';
import styled from 'styled-components';

import { Push } from '/components/Link';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import BasicContainer from '/imports/homepageV3/ui/atoms/BasicContainer';
import BasicSectionWrapper from '/imports/homepageV3/ui/atoms/BasicSectionWrapper';
import PrimaryBtn from '/imports/homepageV3/ui/atoms/PrimaryBtn';
import StyledH1 from '/imports/homepageV3/ui/atoms/StyledH1';
import StyledImage from '/imports/homepageV3/ui/atoms/StyledImage';
import IconRight from '/imports/homepageV3/ui/atoms/svg/IconRight';
import { DISABLE_UPLOAD_RESUME_EXP, DISABLE_UPLOAD_RESUME_EXP_MOBILE, staticFile } from '/lib/helpers';

const Section1 = ({ userIdentified, onClickLandingCTA }) => {
  const { t, locale } = useIntl();
  const { trackEvent } = useTracking();
  const { isMobile } = useResponsive();
  const uploadResumeExp = marvelEmitter.getActiveVariant('exp_landing_upload_resume');

  const STEPS = [
    { number: 1, text: t(`resumizeme_landing_sec1_step1`) },
    { number: 2, text: t(`resumizeme_landing_sec1_step2`) },
    { number: 3, text: t(`resumizeme_landing_sec1_step3`) },
  ];

  const handleRedirect = () => {
    trackEvent('cta_click', { isUploadResume: true });
    Push(`/upload-resume`, locale);
  };

  const isDisableUploadResume =
    (DISABLE_UPLOAD_RESUME_EXP_MOBILE.includes(locale) && isMobile) ||
    (DISABLE_UPLOAD_RESUME_EXP.includes(locale) && !isMobile);

  const RenderCTA = () => {
    if (uploadResumeExp === 'with_upload' || isDisableUploadResume) {
      return (
        <UploadCTAWrapper>
          <ImportButton onClick={handleRedirect} $isUpload>
            <div>{t('landing.uploadcta')}</div>
            <IconRight />
          </ImportButton>
          <PrimaryBtn onClick={onClickLandingCTA}>
            <div>{!userIdentified ? t('landing.cta') : t('landing.my_resumes')}</div>
            <IconRight />
          </PrimaryBtn>
        </UploadCTAWrapper>
      );
    } else {
      return (
        <PrimaryBtn onClick={onClickLandingCTA}>
          <div>{!userIdentified ? t('landing.cta') : t('landing.my_resumes')}</div>
          <IconRight />
        </PrimaryBtn>
      );
    }
  };

  return (
    <Section1Wrapper>
      <Section1Container>
        <CenterWrapper>
          <CenterDescription>
            <StyledH1>{t('resumizeme_landing_sec1_title')}</StyledH1>
            <StyledText>{t('resumizeme_landing_sec1_text')}</StyledText>
            {RenderCTA()}
          </CenterDescription>
          <CenterImageWrapper>
            <ClickableImage
              onClick={onClickLandingCTA}
              src={staticFile('img/landingv3/resume-1-720.png')}
              loading="lazy"
              sizes="(max-width: 479px) 87vw, (max-width: 767px) 44vw, (max-width: 991px) 33vw, 28vw"
              $srcset={`${staticFile('img/landingv3/resume-1-720.png')} 410w, ${staticFile(
                'img/landingv3/resume-1-720.png',
              )} 800w`}
              alt="resume preview 720"
            />
          </CenterImageWrapper>
        </CenterWrapper>
        <StepsWrapper>
          <Steps>
            {STEPS.map((step) => (
              <Step key={step.number}>
                <StepNumber>{step.number}</StepNumber>
                <StepText>{step.text}</StepText>
              </Step>
            ))}
          </Steps>
        </StepsWrapper>
      </Section1Container>
    </Section1Wrapper>
  );
};

const ImportButton = styled(PrimaryBtn)`
  background-color: #1688fe !important;
  &:hover {
    background-color: #0072e8 !important;
  }
`;

const UploadCTAWrapper = styled(Flex)`
  gap: 12px;
`;

const Section1Wrapper = styled(BasicSectionWrapper)`
  padding-top: 0px !important;
  @media (max-width: 938px) {
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
  }
  @media (max-width: 479px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
`;
const Section1Container = styled(BasicContainer)`
  margin: 0 32px;
  @media (max-width: 767px) {
    margin: 0;
  }
`;
const ClickableImage = styled(StyledImage)`
  cursor: pointer;
`;
const CenterWrapper = styled.div`
  display: flex;
  padding-top: 62px;
  padding-right: 48px;
  padding-left: 48px;
  justify-content: space-between;
  align-items: stretch;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #eff5ff;
  @media (max-width: 938px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 479px) {
    padding-right: 32px;
    padding-left: 32px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;
const CenterDescription = styled.div`
  max-width: 560px;
  margin-top: 60px;
  margin-bottom: 48px;
  @media (max-width: 1200px) {
    max-width: 51%;
    margin-top: 0px;
  }
  @media (max-width: 991px) {
    max-width: 51%;
    margin-top: 0px;
  }
  @media (max-width: 938px) {
    display: flex;
    max-width: none;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;
const StyledText = styled.div`
  margin-bottom: 24px;
  font-size: 18px;
`;
const CenterImageWrapper = styled.div`
  width: 40%;
  box-shadow: 0 0 40px 0 rgba(0, 67, 166, 0.12);
  @media (max-width: 767px) {
    width: 50%;
  }
  @media (max-width: 479px) {
    width: auto;
  }
`;
const StepsWrapper = styled.div`
  padding: 32px 3%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #0043a6;
  @media (max-width: 991px) {
    padding: 32px 10px;
  }
  @media (max-width: 767px) {
    padding: 32px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;
const Steps = styled.div`
  display: flex;
  max-width: 986px;
  margin: 0 max(75px, 50% - 986px/2);
  justify-content: space-between;
  @media (min-width: 636px) {
    gap: 64px;
  }
  @media (max-width: 991px) {
    gap: 72px;
    margin: 0 min(75px, 5%);
  }
  @media (max-width: 767px) {
    max-width: none;
    margin: 0 auto;
  }
  @media (max-width: 635px) {
    flex-direction: column;
    gap: 0;
    > div:not(:last-child) {
      margin-bottom: 48px;
    }
  }
`;
const Step = styled.div`
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: 8px;
  }
  font-size: 18px;
  font-weight: 500;
`;
const StepNumber = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  padding: 6px;
`;
const StepText = styled.div`
  color: #fff;
  @media (max-width: 767px) {
    width: 70%;
  }
  @media (max-width: 479px) {
    width: auto;
  }
`;
export default Section1;
