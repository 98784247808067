import styled, { css } from 'styled-components';

import TextAtom from '/imports/pdf/core/ui/atoms/Text';

const Heading = styled(TextAtom)`
  ${({ theme, theme: { headingFont, convertPx, color, setGenderStyle }, blocktitle, light, children }) => css`
    font-family: ${headingFont()};
    font-size: ${convertPx(16)};
    color: #808183;
    text-transform: uppercase;
    margin-bottom: ${convertPx(18)};
    word-break: break-word;
    page-break-inside: avoid;
    break-inside: avoid;
    ${blocktitle &&
    css`
      color: #414142;
      ${color &&
      color === 'budapestGirlBlue' &&
      css`
        color: #2c3a4b;
      `}
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: ${convertPx(-4)};
        height: ${convertPx(1)};
        background-color: #414142;
        ${color &&
        color === 'budapestGirlBlue' &&
        css`
          background-color: #bcbec0;
        `}
      }
    `}
    ${light &&
    css`
      color: #e6e7e8;
      ${color === 'budapestGirlBlue' &&
      css`
        color: #a28848;
      `}
      ${theme.accentTitleColors[color] &&
      css`
        color: ${theme.accentTitleColors[color]};
      `}
      &:after {
        background-color: transparent;
      }
    `}
  `}
`;

export default Heading;
