import React from 'react';
import styled, { css } from 'styled-components';

const PhoneIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 256 256"
      xmlSpace="preserve"
    >
      <defs></defs>
      <g
        style={{
          stroke: 'none',
          strokeWidth: 0,
          strokeDasharray: 'none',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1,
        }}
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
      >
        <circle
          cx={45}
          cy={45}
          r={45}
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform="  matrix(1 0 0 1 0 0) "
        />
        <path
          d="M 57.332 70.088 c -1.955 0.001 -4.074 -0.313 -6.32 -0.946 c -6.303 -1.777 -12.904 -5.885 -18.586 -11.568 c -5.682 -5.682 -9.79 -12.283 -11.568 -18.586 c -1.84 -6.528 -0.978 -11.999 2.429 -15.406 c 0.124 -0.124 0.25 -0.244 0.38 -0.36 c 0.033 -0.046 0.07 -0.09 0.112 -0.131 l 1.819 -1.819 c 0.877 -0.877 2.042 -1.36 3.282 -1.36 c 1.24 0 2.405 0.483 3.282 1.36 l 6.976 6.977 c 1.809 1.809 1.809 4.754 0 6.563 L 36.149 37.8 c -0.753 0.753 -0.753 1.979 0 2.732 L 49.468 53.85 c 0.729 0.729 2.002 0.729 2.732 0 l 2.988 -2.988 c 1.809 -1.808 4.755 -1.81 6.564 -0.001 l 6.977 6.977 c 0.876 0.876 1.359 2.042 1.359 3.281 s -0.483 2.405 -1.359 3.281 l -1.819 1.819 c -0.042 0.042 -0.087 0.08 -0.133 0.114 c -0.117 0.13 -0.236 0.256 -0.36 0.379 C 64.182 68.948 61.058 70.088 57.332 70.088 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </SvgIcon>
  );
};

const SvgIcon = styled.svg`
  stroke: none;
  width: ${({ theme }) => theme.convertPx(24)};
  height: ${({ theme }) => theme.convertPx(24)};
  ${({ theme, theme: { convertPx, isRTL, colors, setGenderStyle, color }, isLocation, isPhone }) => css`
    margin-right: ${convertPx(isRTL ? 0 : 5)};
    margin-left: ${convertPx(isRTL ? 5 : 0)};
    ${isRTL &&
    css`
      margin-left: ${convertPx(5)};
      margin-right: 0;
    `}
    height: ${convertPx(24)};
    width: ${convertPx(24)};
    path {
      fill: #fff;
    }
    circle {
      fill: ${colors[color]};
    }

    ${(theme.accentTitleColors[color] || color == 'black') &&
    css`
      circle {
        fill: ${theme.accentTitleColors[color]};
      }
      ${((color && color === 'budapestGirlBlue') ||
        (theme.isCoverLetter && color == 'black' && theme.gender('female') == true)) &&
      css`
        background-color: transparent;
        path {
          fill: #a28848;
        }
        circle {
          fill: transparent;
        }
      `}
    `}
    justify-content:center
    align-items: center;
  `}
`;

export default PhoneIcon;
