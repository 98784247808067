import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isFirefox } from 'react-device-detect';
import styled, { css } from 'styled-components';

import ButtonAtom from '/imports/core/ui/atoms/Button';
import InputStripe from '/imports/core/ui/atoms/InputStripe';
import { PencilIcon } from '/imports/core/ui/assets';
import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { CheckIcon, UndoIcon, XmarkIcon } from 'imports/generator/ui/assets';
import { getBlockTranslationType } from 'imports/generator/api/helpers';
import { handleDeselect, handleSelect, isFormInput } from '/imports/core/ui/helpers';

@withIntl
@withResponsiveContext
class EditableTitle extends PureComponent {
  static propTypes = {
    defaultvalue: PropTypes.string,
    onSave: PropTypes.func,
    onClick: PropTypes.func,
    toggleEditable: PropTypes.func,
    controlled: PropTypes.bool,
    editable: PropTypes.bool,
    isShortTitle: PropTypes.bool,
    isCoverLetter: PropTypes.bool,
    t: PropTypes.func,
    className: PropTypes.string,
    visibleIcon: PropTypes.any,
    onlyMobile: PropTypes.string,
    isCustomSection: PropTypes.bool,
    isRenameSectionTitle: PropTypes.bool,
    type: PropTypes.string,
    inputId: PropTypes.string,
    trackEvent: PropTypes.func,
  };

  state = {
    editable: false,
    value: this.props.defaultvalue,
    defaultTitle: this.props.defaultvalue,
    width: 0,
    height: 0,
    changed: false,
    disabled: false,
    saved: false,
    titleClicked: false,
    textHeight: 'auto',
  };

  ignoreBlur = false;

  handleResize() {
    const textarea = document.getElementById('titleInputTextarea');
    if (textarea) {
      textarea.style.height = '1px';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }

  componentDidMount() {
    const { defaultvalue, t, type, isCustomSection } = this.props;
    try {
      document.fonts?.ready.then(() => {
        this.handleResize();
        this.setState({
          width: this.span?.clientWidth,
          height: this.span?.scrollHeight,
        });
      });
      if (this.props.isRenameSectionTitle) {
        if (
          (!isCustomSection && defaultvalue.toLowerCase() !== t(getBlockTranslationType(type)).toLowerCase()) ||
          (isCustomSection && defaultvalue.toLowerCase() !== t('untitled').toLowerCase())
        ) {
          this.setState({ saved: true });
        }
      }
    } catch (err) {
      this.handleResize();
      this.setState({
        width: this.span?.clientWidth,
        height: this.span?.scrollHeight,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const defaultHeight = this.props.isShortTitle ? 23 : 45;
    if (this.state.value?.toLowerCase() !== prevState.value?.toLowerCase()) {
      this.handleResize();
      this.setState({
        width: this.span?.clientWidth === 0 ? 10 : this.span?.clientWidth,
        height: this.span?.scrollHeight === 0 ? defaultHeight : this.span?.scrollHeight,
        changed: true,
      });
    }
    if (prevProps.editable !== this.props.editable) {
      this.setState({ editable: this.props.editable }, () => {
        if (this.props.editable) {
          this.input.focus();
          !this.props.isCustomSection && handleSelect(this.input);
        }
      });
    }
  }

  getBlockName = () => {
    const { isCustomSection, type, t } = this.props;

    return isCustomSection ? t('untitled', 'en') : t(getBlockTranslationType(type), 'en') ?? t('untitled', 'en');
  };

  save = () => {
    const { onSave, t, type, trackEvent } = this.props;
    const { value, changed } = this.state;
    this.toggleEditable();
    if (value === '') this.setState({ value: t('untitled'), changed: true });
    if (onSave && changed) {
      const savedValue = value.trim() || 'Untitled';
      this.setState({ value: savedValue });
      onSave(savedValue);
      if (this.props.isRenameSectionTitle) {
        if (value === t(getBlockTranslationType(type))) {
          this.setState({ saved: false });
        } else {
          this.setState({ saved: true });

          if (trackEvent) {
            const blockName = this.getBlockName();
            trackEvent('renamed_section_title', { type, blockName });
          }
        }
      }
    }
    this.ignoreBlur = false;
    if (isFirefox) return this.fireFoxScroll(false);
  };

  undoTitle = () => {
    this.toggleEditable();
    const { onSave, t, type, isCustomSection } = this.props;

    const blockName = this.getBlockName();
    const defaultTitle = isCustomSection ? t('untitled') : t(getBlockTranslationType(type)) ?? t('untitled');

    this.setState({ value: defaultTitle }, () => {
      if (onSave) onSave(blockName || 'Untitled');
      this.setState({ changed: false, saved: false }, () => {
        if (isFirefox) this.fireFoxScroll(false);
      });
    });
  };

  onBlur = (e) => {
    if (this.ignoreBlur) {
      this.ignoreBlur = false;
      return;
    }
    this.save();
    handleDeselect();
  };

  toggleEditable = () => {
    if (this.props.toggleEditable) {
      this.props.toggleEditable();
    } else {
      this.setState((st) => ({ editable: !st.editable }));
    }
  };

  onClick = (e) => {
    if (!this.props.onClick) {
      if (!this.props.controlled && !this.state.editable) {
        this.toggleEditable(e);
      }
    } else if (!this.state.editable) {
      this.props.onClick(e);
    }
  };

  onTitleClicked = (e) => {
    if (!this.state.editable) {
      this.toggleEditable();
    }
  };

  onIgnoreBlur = () => {
    this.ignoreBlur = true;
    handleDeselect();
  };

  onChange = (e) => {
    this.setState({ value: e.target.value });
  };

  getInputRef = (node) => {
    this.input = node;
  };

  onClickEdit = () => {
    this.toggleEditable();
    this.input.focus();
    if (isFirefox) return this.fireFoxScroll(true);
    handleSelect(this.input);
  };

  handleKeyPress = (e) => {
    if (e.charCode === 13) this.input.blur();
  };

  fireFoxScroll = (open) => {
    let { value = '' } = this.state;
    const { t } = this.props;
    if (!open) {
      if (this.input.scrollLeft) this.input.scrollLeft = 0;
      return;
    }
    value = value === 'Untitled' ? t('untitled') : value;
    if (isFormInput(this.input)) this.input.setSelectionRange(0, value.length);
    if (this.input.scrollWidth) this.input.scrollLeft = this.input.scrollWidth;
  };

  render() {
    const {
      className,
      controlled,
      onClick,
      visibleIcon,
      isShortTitle,
      isCoverLetter = false,
      onlyMobile,
      t,
      isCustomSection,
      editable: propEditable,
      isRenameSectionTitle,
    } = this.props;

    let { value = '', width, editable, height, saved, changed } = this.state;
    value = value === 'Untitled' ? t('untitled') : value;
    const disabled = controlled ? !editable : false;
    const isOverlapped = isShortTitle && (onlyMobile === 'xs' ? value.length >= 10 : value.length >= 15);
    const iconProps = {};
    if (isCoverLetter) {
      iconProps.width = '20';
      iconProps.height = '20';
    }

    const inputProps = {
      type: 'text',
      ref: this.getInputRef,
      className: className,
      onChange: this.onChange,
      controlled: controlled,
      value: value,
      width: width,
      height: height,
      disabled: isRenameSectionTitle ? this.state.disabled : disabled,
      editable: editable,
      onKeyPress: this.handleKeyPress,
      isOverlapped: isOverlapped,
      onBlur: this.onBlur,
      ...(isRenameSectionTitle ? { onClick: this.onTitleClicked } : {}),
      id: this.props.inputId,
    };

    const contentEditableBlurHandler = (e) => {
      this.setState({ value: e.target.innerText });
      setTimeout(() => {
        this.onBlur();
      }, 200);
    };

    return (
      <Fragment>
        <Cont controlled={controlled} isOverlapped={isOverlapped} data-testid="editable-title" isRenameSectionTitle>
          {onClick && !editable && <ClickOverlay onClick={this.onClick} width={width} />}
          {isShortTitle || isCustomSection ? (
            <ShortTitle {...inputProps} />
          ) : isRenameSectionTitle ? (
            <ContentEditable
              {...inputProps}
              isRenameSectionTitle
              isEmpty={!value}
              id="titleInputTextarea"
              contentEditable
              onBlur={contentEditableBlurHandler}
            >
              {value}
            </ContentEditable>
          ) : (
            <TitleInput {...inputProps} isRenameSectionTitle isEmpty={!value} id="titleInputTextarea" />
          )}
          <InputStripe />
          {!controlled && (
            <Button
              unstyled
              visibleIcon={visibleIcon}
              onClick={this.onClickEdit}
              isCoverLetter={isCoverLetter}
              isShortTitle={isShortTitle}
            >
              <PencilIcon {...iconProps} />
            </Button>
          )}
          {isRenameSectionTitle && propEditable && (
            <ButtonEditGroup>
              {saved && (
                <ButtonTitle undo unstyled onClick={this.undoTitle} onMouseDown={this.onIgnoreBlur}>
                  <UndoIcon />
                </ButtonTitle>
              )}
            </ButtonEditGroup>
          )}
          <HiddenSpan
            className={className}
            ref={(r) => (this.span = r)}
            dangerouslySetInnerHTML={{ __html: value }}
            isShortTitle={isShortTitle}
          />
        </Cont>
      </Fragment>
    );
  }
}

EditableTitle.displayName = 'EditableTitle';

const Cont = styled.div`
  position: relative;
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  display: flex;
  align-items: start;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      flex-direction: row-reverse;
      gap: 10px;
    `}
  ${(p) =>
    p.isOverlapped &&
    css`
      max-width: 100%;
    `}
  ${(p) =>
    p.controlled &&
    css`
      button {
        right: -20px;
      }
    `}
  ${({ isRenameSectionTitle }) =>
    isRenameSectionTitle &&
    css`
      align-items: center;
    `}
  ${({ theme }) => theme.max('md')`
      button {
        opacity: 1;
        svg path {
          fill: #282b32;
        }
      }
  `}

  &:hover {
    button {
      opacity: 1;
      svg path {
        fill: ${(p) => p.theme.colors.primary};
      }
    }
  }
`;

const HiddenSpan = styled.span`
  visibility: hidden;
  position: absolute !important;
  font-weight: 700;
  top: -10000px;
  left: -10000px;
  white-space: pre-wrap;
  word-break: break-all
    ${({ isShortTitle }) =>
      isShortTitle &&
      css`
        white-space: nowrap;
      `};
`;
const inputStyles = css`
  resize: none;
  overflow: hidden;
  height: ${(p) => p.height}px;
  display: inline-block;
  border: none;
  margin: 0;
  line-height: 1.4;
  color: ${(p) => p.theme.colors.black};
  font-weight: 700;
  background: transparent;
  padding: 0;
  width: ${(p) => (!p.width ? '100%' : `${p.width + (p.controlled && !p.editable ? 1 : 25)}px`)};

  &:disabled {
    color: ${(p) => p.theme.colors.black};
    -webkit-text-fill-color: ${(p) => p.theme.colors.black};
    opacity: 1;
    border: none !important;
    background: none !important;
  }

  ${({ controlled, theme: { isRTL } }) =>
    !controlled &&
    css`
      padding: 0 5px;
      ${isRTL &&
      css`
        padding-right: 0;
      `}
    `}

  /* &:focus > div {
    visibility: visible;
    transform: rotateY(0);
    background-color: ${(p) => p.theme.colors.primary};
    ${({ theme }) =>
    theme.max('xs')`
      background-color: #1688fe;
    `}
  }
  &:focus > button {
    visibility: hidden;
  } */
  ${(p) =>
    p.isOverlapped &&
    css`
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      text-align: right !important;
    `}
`;
const ShortTitle = styled.input`
  ${inputStyles}
`;

const TitleInput = styled.textarea`
  word-break: break-all;
  resize: none;
  overflow: hidden;
  border: 1px solid transparent;
  boxsizing: 'border-box';
  margin: 0;
  line-height: 1.4;
  font-weight: 700;
  background: transparent;
  padding: 0;
  width: 100%;

  &:disabled {
    color: ${(p) => p.theme.colors.black};
    -webkit-text-fill-color: ${(p) => p.theme.colors.black};
    opacity: 1;
    border: none !important;
    background: none !important;
  }
  ${(p) =>
    p.isOverlapped &&
    css`
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${({ controlled, theme: { isRTL } }) =>
    !controlled &&
    css`
      padding: 0 5px;
      ${isRTL &&
      css`
        padding-right: 0;
      `}
    `}
    
  ${({ isRenameSectionTitle }) =>
    isRenameSectionTitle &&
    css`
      height: ${(p) => (!p.height ? '100%' : `${p.height + 3}px`)};
      text-transform: capitalize;
    `}
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      height: 25px;
    `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      text-align: right !important;
    `}
`;

const ClickOverlay = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ width }) => width}px;
  height: 100%;
  cursor: pointer;
  z-index: 1;

  &:hover + ${TitleInput}, &:hover + ${ShortTitle} {
    color: ${(p) => p.theme.colors.primary};
  }
`;

const Button = styled(ButtonAtom)`
  margin-top: ${({ isCoverLetter }) => (isCoverLetter ? '10px' : '8px')};
  opacity: ${({ isCoverLetter }) => (isCoverLetter ? 1 : 0)};
  ${({ isShortTitle }) =>
    isShortTitle &&
    css`
      margin-top: -3px;
    `}
  cursor: pointer;
  color: ${(p) => p.theme.colors.gray.regular};
  svg path {
    fill: #282b32;
  }

  &:hover svg path {
    fill: ${(p) => p.theme.colors.primary};
  }

  ${({ visibleIcon, isCoverLetter }) =>
    visibleIcon &&
    css`
      opacity: 1;

      svg {
        width: ${isCoverLetter ? '20px' : '11px'};
        height: ${isCoverLetter ? '20px' : '11px'};
      }
    `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: -15px;
      right: auto;
    `}
    
  ${({ close, save, undo }) =>
    (close || save || undo) &&
    css`
      opacity: 1;
    `}
`;

const ButtonEditGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const ButtonTitle = styled.div`
  cursor: pointer;
  width: max-content;
  height: max-content;
  display: flex;
  svg {
    transform: scale(1.3);
  }
  ${({ edit }) =>
    edit &&
    css`
      svg {
        fill: #1688fe;
      }

      &:hover svg path {
        fill: #1688fe;
      }
    `}

  ${({ delete: Delete }) =>
    Delete &&
    css`
      svg:hover rect {
        fill: #1688fe;
      }
    `}

  ${({ strokeHover }) =>
    strokeHover &&
    css`
      svg {
        path {
          stroke: rgb(191, 197, 214);
        }
      }

      :hover svg path,
      :hover svg rect {
        stroke: #1688fe;
      }
    `}
  ${({ close }) =>
    close &&
    css`
      svg {
        width: 20px;
        height: auto;
        path {
          stroke: white;
        }
      }
    `}
  ${({ save }) =>
    save &&
    css`
      svg {
        width: 20px;
        height: auto;
        path {
          stroke: white;
        }
      }
    `}
  ${({ undo }) =>
    undo &&
    css`
      svg {
        width: 20px;
        height: auto;
        path {
          stroke: white;
        }
      }
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      svg {
        margin-left: 0 !important;
        margin-right: 10px;
      }
    `}
`;

const ContentEditable = styled.div`
  ${inputStyles}
  width: fit-content!important;
  margin-right: 10px !important;
  word-break: break-word;
  height: auto !important;
  cursor: text;
`;

export default EditableTitle;
