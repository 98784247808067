import { PureComponent } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { darken } from 'polished';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';
import nookies from 'nookies';

import { BREAKPOINTS } from '/styles/variables';
import Button from '/imports/core/ui/atoms/Button';
import {
  FAKE_TEMPLATES,
  FEATURED_TEMPLATES,
  FEATURED_TEMPLATES_MESSAGES,
  TEMPLATES,
} from '/imports/generator/api/constants';
import { NextIcon, PrevIcon } from '/imports/onboarding/ui/assets';
import { randomizeTemplates, reorderSequencedTemplates } from '/imports/generator/api/helpers';
import { OnboardingSliderTemplate as Template } from '/imports/onboarding/ui/atoms';
import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withTracking } from '/imports/core/hooks/useTracking';
import { localAiSelect } from 'lib/helpers';
import { withAccount } from 'imports/core/api/accounts/accountContext';

@withTracking
@withRouter
@withIntl
@withResponsiveContext
@withAccount
class OnboardingSlider extends PureComponent {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    isPopupOpen: PropTypes.bool,
  };

  state = {
    width: 0,
    height: 0,
    current: 0,
    templates: [],
    loading: true,
  };

  updateDimensions = () => {
    if (!this._wrapper) {
      return;
    }
    const top = this._wrapper.offsetTop;
    const wHeight = window.innerHeight;
    const footer = (window.innerWidth > BREAKPOINTS.md && 102) || 240;
    const height = Math.max(300, wHeight - top - 120 - footer);
    const width = (height / 280) * 190;
    this.setState({ width, height });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      const templates = this.shuffleTemplates();
      const filteredTemplates = this.filterTemplatesByRole(templates);
      this.setState({
        templates: filteredTemplates,
      });
    }
  }

  componentDidMount() {
    const { sessionStartHandler, trackEvent, host, locale, isMobile } = this.props;
    sessionStartHandler();
    let templates = this.shuffleTemplates();
    templates = this.filterTemplatesByRole(templates);
    let obj = {
      random_default_template: templates[0].name,
    };
    obj.exp_job_track = marvelEmitter.getActiveVariant('job-tracking');
    obj.exp_template_budapest = marvelEmitter.getActiveVariant('exp_template_budapest');
    if (localAiSelect.includes(locale)) obj.exp_select_cv_ai = marvelEmitter.getActiveVariant('exp_select_cv_ai');
    switch (locale) {
      case 'he':
        obj.exp_template_english_vs_hebrew = marvelEmitter.getActiveVariant('exp_template_english_vs_hebrew');
        break;
      case 'nl':
        obj.exp_template_english_vs_dutch = marvelEmitter.getActiveVariant('exp_template_english_vs_dutch');
        break;
      case 'ja':
        obj.exp_template_english_vs_japanese = marvelEmitter.getActiveVariant('exp_template_english_vs_japanese');
        break;
      case 'az':
        obj.exp_template_english_vs_azerbaijani = marvelEmitter.getActiveVariant('exp_template_english_vs_azerbaijani');
        break;
      case 'ca':
        obj.exp_template_english_vs_catalan = marvelEmitter.getActiveVariant('exp_template_english_vs_catalan');
        break;
      case 'kk':
        obj.exp_template_english_vs_kazakh = marvelEmitter.getActiveVariant('exp_template_english_vs_kazakh');
        break;
      case 'ka':
        obj.exp_template_english_vs_georgian = marvelEmitter.getActiveVariant('exp_template_english_vs_georgian');
        break;
      case 'el':
        obj.exp_template_english_vs_greek = marvelEmitter.getActiveVariant('exp_template_english_vs_greek');
        break;
      case 'pt':
        obj.exp_template_english_vs_portuguese = marvelEmitter.getActiveVariant('exp_template_english_vs_portuguese');
        break;
      case 'tj':
        obj.exp_template_english_vs_tajik = marvelEmitter.getActiveVariant('exp_template_english_vs_tajik');
        break;
      case 'sr':
        obj.exp_template_english_vs_serbian = marvelEmitter.getActiveVariant('exp_template_english_vs_serbian');
        break;
    }

    if (marvelEmitter.getActiveVariant('exp_budapest_cali_lima')) {
      obj.exp_budapest_cali_lima = marvelEmitter.getActiveVariant('exp_budapest_cali_lima');
    }

    trackEvent('template_view', obj);
    this.setState({
      templates,
    });

    window.addEventListener('resize', this.updateDimensions);

    try {
      document.getElementsByTagName('body')[0].classList.remove('_container');
    } catch (error) {
      console.error("Can't find body tag");
    }
    this.setState({ loading: false, templates });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    try {
      document.getElementsByTagName('body')[0].classList.remove('_container');
    } catch (error) {
      console.error("Can't find body tag");
    }
  }

  shuffleTemplates() {
    const { locale } = this.props;

    let visibleTemplates = TEMPLATES.filter((template) => !template.hidden);
    let randomizedTemplate = randomizeTemplates(
      visibleTemplates,
      FAKE_TEMPLATES,
      FEATURED_TEMPLATES.slice(0, 2),
      FEATURED_TEMPLATES.slice(2),
    ).filter((template) => {
      if (typeof template.hiddenOnSlider == 'function') {
        return !template.hiddenOnSlider({ locale: this.props.locale });
      }
      return !template.hiddenOnSlider;
    });

    const caliIndex = randomizedTemplate.findIndex((template) => template.id === 'cali');
    const limaIndex = randomizedTemplate.findIndex((template) => template.id === 'lima');
    let caliTemplate, limaTemplate;

    if (caliIndex > -1) {
      [caliTemplate] = randomizedTemplate.splice(caliIndex, 1);
    }
    if (limaIndex > -1) {
      [limaTemplate] = randomizedTemplate.splice(limaIndex, 1);
    }

    if (locale === 'en') {
      if (caliTemplate) {
        randomizedTemplate.splice(3, 0, caliTemplate);
      }
      if (limaTemplate) {
        randomizedTemplate.splice(-3, 0, limaTemplate);
      }
    }

    return randomizedTemplate;
  }

  formateTepmlates(templates) {
    const templateOrder = [
      'budapest',
      'chicago',
      'sydney',
      'brunei',
      'perth',
      'denver',
      'prague',
      'atsresume',
      'budapest-v2',
      'riga',
      'rotterdam',
    ];

    return templateOrder.map((id) => templates.find((template) => template.id === id));
  }

  getTemplateById(templates, id) {
    return templates.find((template) => template.id === id);
  }

  filterTemplatesByRole(templates) {
    const { currentUser, locale } = this.props;
    const formettedTemplates = this.formateTepmlates(templates);
    const { agent_client_id } = nookies.get({});

    if (locale === 'es') {
      const brunei = this.getTemplateById(formettedTemplates, 'brunei');
      const denver = this.getTemplateById(formettedTemplates, 'denver');
      const bruneiIndex = formettedTemplates.indexOf(brunei);
      const denverIndex = formettedTemplates.indexOf(denver);

      if (Math.random() < 0.5) {
        formettedTemplates.splice(bruneiIndex, 1, denver);
        formettedTemplates.splice(denverIndex, 1, brunei);
      }
    }

    const filterdTemplates =
      locale === 'es'
        ? formettedTemplates
        : templates.filter((template) => {
            return template?.id !== 'denver' && template?.id !== 'brunei';
          });

    const isAgentOrAdmin = currentUser && (currentUser.role === 'agent' || currentUser.role === 'admin');
    if ((agent_client_id !== undefined && agent_client_id !== null) || isAgentOrAdmin) {
      return filterdTemplates;
    } else {
      return filterdTemplates.filter((template) => template?.id !== 'atsresume' && template?.id !== 'budapest-v2');
    }
  }

  getWrapperRef = (node) => {
    this._wrapper = node;
    this.updateDimensions();
  };

  getSlider = (node) => {
    this._slider = node;
  };

  next = () => {
    this._slider.slickNext();
  };

  previous = () => {
    this._slider.slickPrev();
  };

  onTemplateSelect = (index) => {
    this.setState({ current: index });
  };

  render() {
    const { onSelect, isPopupOpen } = this.props;
    if (this.state.loading) return null;
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      centerMode: true,
      variableWidth: true,
      focusOnSelect: true,
      beforeChange: (current, next) => this.setState({ current: next }),
    };
    const { templates } = this.state;
    const featured_experiment_templates = FEATURED_TEMPLATES;
    return (
      <SliderWrapper ref={this.getWrapperRef}>
        <SliderControlLeft withoutBackground={isPopupOpen}>
          <SliderControlButton onClick={this.previous}>
            <PrevIcon />
          </SliderControlButton>
        </SliderControlLeft>
        <SliderControlRight withoutBackground={isPopupOpen}>
          <SliderControlButton onClick={this.next}>
            <NextIcon />
          </SliderControlButton>
        </SliderControlRight>
        <Slider ref={this.getSlider} {...settings}>
          {templates.map((template, i) => {
            const featured = !template?.hideLabel && featured_experiment_templates.includes(template.id);
            let message = '';
            if (featured) {
              switch (i) {
                case 0:
                  message = FEATURED_TEMPLATES_MESSAGES[0];
                  break;

                case 1:
                  message = FEATURED_TEMPLATES_MESSAGES[1];
                  break;

                case templates.length - 1:
                  message = FEATURED_TEMPLATES_MESSAGES[2];
                  break;

                default:
                  break;
              }
            }
            return (
              <Template
                selected={i === this.state.current}
                key={`${template.image || template.id}`}
                {...template}
                {...this.state}
                featured={featured}
                message={message}
                onSelect={onSelect}
                onTemplateSelect={this.onTemplateSelect}
              />
            );
          })}
        </Slider>
      </SliderWrapper>
    );
  }
}

const SliderWrapper = styled.section`
  text-align: center;
  position: relative;
  margin-top: 70px;

  ${({ theme }) => theme.max('md')`
    margin: 0 -20px;
  `}
  ${({ theme }) => theme.min('md')`
    .slick-list {
      overflow: initial;
    }
  `}

  margin-top: 0;
  padding: 45px 0 20px;

  & .slick-list {
    overflow: initial;
  }

  ${({ theme }) => theme.max('md')`
      padding: 0 0 20px;
    `}
`;

const SliderControl = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 160px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.max('md')`
    display: none;
  `}
`;

const SliderControlLeft = styled(SliderControl)`
  left: -20px;
  justify-content: flex-start;
  background: ${({ theme }) =>
    `linear-gradient(to left, rgba(255, 255, 255, 0.001), ${theme.colors.gray.light2} 55%);`};
  ${({ withoutBackground }) =>
    withoutBackground &&
    css`
      background: transparent;
    `}
`;

const SliderControlRight = styled(SliderControl)`
  right: -20px;
  justify-content: flex-end;
  background: ${({ theme }) =>
    `linear-gradient(to right, rgba(255, 255, 255, 0.001), ${theme.colors.gray.light2} 55%);`};
  ${({ withoutBackground }) =>
    withoutBackground &&
    css`
      background: transparent;
    `}
`;

const SliderControlButton = styled((p) => <Button unstyled {...p} />)`
  width: 52px;
  height: 52px;
  border-radius: 52px;
  margin: 36px;
  cursor: pointer;
  color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  user-select: none;
  font-size: 32px;
  background: ${({ theme }) => theme.colors.black2};
  &:hover {
    background: ${({ theme }) => darken(0.05, theme.colors.black2)};
    border-color: ${({ theme }) => darken(0.05, theme.colors.black2)};
  }
`;

export default OnboardingSlider;
