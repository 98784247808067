import DateRangeDisplay from './DateRangeDisplay';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import useIntl from '/imports/core/api/useIntl';

import BlockNestedItem from '/imports/pdf/budapest/ui/atoms/BlockNestedItem';
import BreakAvoidWrapper from '/imports/pdf/core/ui/atoms/BreakAvoidWrapper';
import { getClickToEditProps, isDescriptionEmpty, parseDraftText } from '/imports/pdf/core/api/helpers';
import StyledItemContainer from '/imports/pdf/budapest/ui/atoms/StyledItemContainer';
import SubHeader from '/imports/pdf/budapest/ui/atoms/SubHeader';
import Text from '/imports/pdf/budapest/ui/atoms/Text';
import View from '/imports/pdf/core/ui/atoms/View';
import EditRedirectionWrapper from 'imports/pdf/core/ui/components/EditRedirectionWrapper';

const RangeActivityDisplay = (props) => {
  const { left } = props;
  if (left) return <BlockLeftComponent {...props} />;
  return <BlockRightComponent {...props} />;
};

const BlockLeftComponent = (props) => {
  const {
    title,
    subtitle,
    employer,
    startDate,
    endDate,
    city,
    current,
    description,
    showOnlyYear,
    hideDates,
    hideStartDate,
    language,
    publicationTitle,
    isEducation,
    font,
    itemId,
    fieldsName,
  } = props;
  return (
    <BlockNestedItemLeft>
      <BreakAvoidWrapper>
        {title && (
          <EditRedirectionWrapper {...getClickToEditProps(itemId, { title }, fieldsName)}>
            <LeftSubHead>{title}</LeftSubHead>
          </EditRedirectionWrapper>
        )}
        {subtitle && (
          <EditRedirectionWrapper {...getClickToEditProps(itemId, { subtitle }, fieldsName)}>
            <LeftSubHead>{subtitle}</LeftSubHead>
          </EditRedirectionWrapper>
        )}
        {employer && !isEducation && (
          <EditRedirectionWrapper {...getClickToEditProps(itemId, { employer }, fieldsName)}>
            <LeftSubHead>{employer}</LeftSubHead>
          </EditRedirectionWrapper>
        )}
        {publicationTitle && (
          <EditRedirectionWrapper {...getClickToEditProps(itemId, { publicationTitle }, fieldsName)}>
            <LeftSubHead>{publicationTitle}</LeftSubHead>
          </EditRedirectionWrapper>
        )}
        <StyledText>
          {isEducation && employer && (
            <EditRedirectionWrapper {...getClickToEditProps(itemId, { employer }, fieldsName)}>
              <span>
                {employer}
                {city || !hideDates ? ' / ' : ''}
              </span>
            </EditRedirectionWrapper>
          )}
          {city && (
            <EditRedirectionWrapper {...getClickToEditProps(itemId, { city }, fieldsName)}>
              <span>
                {city}
                {!hideDates ? ' / ' : ''}
              </span>
            </EditRedirectionWrapper>
          )}
          {!hideDates && (
            <EditRedirectionWrapper
              {...getClickToEditProps(
                itemId,
                { ...(!hideStartDate && { startDate }), ...(!current && { endDate }) },
                fieldsName,
              )}
            >
              <DateRangeDisplay
                startDate={startDate}
                endDate={endDate}
                current={current}
                showOnlyYear={showOnlyYear}
                hideStartDate={hideStartDate}
                language={language}
                left
              />
            </EditRedirectionWrapper>
          )}
        </StyledText>
      </BreakAvoidWrapper>
      {!isDescriptionEmpty(description) && (
        <EditRedirectionWrapper {...getClickToEditProps(itemId, { description }, fieldsName)}>
          <TextCont>
            {description === null || description === '' ? (
              <StyledText>{description}</StyledText>
            ) : (
              <StyledText>{parseDraftText(description, StyledText, font)}</StyledText>
            )}
          </TextCont>
        </EditRedirectionWrapper>
      )}
    </BlockNestedItemLeft>
  );
};

const BlockRightComponent = (props) => {
  const {
    title,
    subtitle,
    employer,
    startDate,
    endDate,
    city,
    current,
    description,
    showOnlyYear,
    last,
    hideDates,
    hideStartDate,
    language,
    shorterList,
    publicationTitle,
    font,
    itemId,
    fieldsName,
    index,
    blockType,
  } = props;
  const { t } = useIntl();
  return (
    <div style={{ pageBreakAfter: 'auto' }}>
      <BlockNestedItem breakableView>
        <LeftSide shorterList={shorterList} last={last}>
          <StyledItemContainer left>
            <BreakAvoidWrapper>
              {employer && (
                <EditRedirectionWrapper {...getClickToEditProps(itemId, { employer }, fieldsName)}>
                  <SubHeader upper small>
                    {employer}
                  </SubHeader>
                </EditRedirectionWrapper>
              )}
              {subtitle && (
                <EditRedirectionWrapper {...getClickToEditProps(itemId, { subtitle }, fieldsName)}>
                  <SubHeader upper small>
                    {subtitle}
                  </SubHeader>
                </EditRedirectionWrapper>
              )}
              {publicationTitle && (
                <EditRedirectionWrapper {...getClickToEditProps(itemId, { publicationTitle }, fieldsName)}>
                  <SubHeader upper small>
                    {publicationTitle}
                  </SubHeader>
                </EditRedirectionWrapper>
              )}
              {city && (
                <EditRedirectionWrapper {...getClickToEditProps(itemId, { city }, fieldsName)}>
                  <CityText>{city}</CityText>
                </EditRedirectionWrapper>
              )}
              {!hideDates && (
                <EditRedirectionWrapper
                  {...getClickToEditProps(
                    itemId,
                    { ...(!hideStartDate && { startDate }), ...(!current && { endDate }) },
                    fieldsName,
                  )}
                >
                  <DateRangeDisplay
                    startDate={startDate}
                    endDate={endDate}
                    current={current}
                    showOnlyYear={showOnlyYear}
                    hideStartDate={hideStartDate}
                    language={language}
                  />
                </EditRedirectionWrapper>
              )}
            </BreakAvoidWrapper>
          </StyledItemContainer>
        </LeftSide>
        <RightSide last={last} $index={index} shorterList={shorterList}>
          <StyledItemContainer>
            <EditRedirectionWrapper {...getClickToEditProps(itemId, { title }, fieldsName)}>
              <SubHeader
                data-tooltip-content={t('click_to_edit_cta')}
                data-tooltip-id={blockType == 'EMPLOYMENT' && index == 0 && `tooltip-preview-hover`}
              >
                {title}
              </SubHeader>
            </EditRedirectionWrapper>
            {!isDescriptionEmpty(description) && (
              <EditRedirectionWrapper {...getClickToEditProps(itemId, { description }, fieldsName)}>
                <TextCont>
                  <Text>{parseDraftText(description, Text, font)}</Text>
                </TextCont>
              </EditRedirectionWrapper>
            )}
          </StyledItemContainer>
          <Border className="decoration-border" />
        </RightSide>
      </BlockNestedItem>
    </div>
  );
};

RangeActivityDisplay.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  employer: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  city: PropTypes.string,
  current: PropTypes.bool,
  description: PropTypes.string,
  showOnlyYear: PropTypes.bool,
  last: PropTypes.bool,
  hideDates: PropTypes.bool,
  hideStartDate: PropTypes.bool,
  language: PropTypes.string,
  left: PropTypes.bool,
  shorterList: PropTypes.number,
  publicationTitle: PropTypes.string,
  isEducation: PropTypes.bool,
  font: PropTypes.string,
};

const Border = styled.div`
  position: absolute;
  ${({ theme, theme: { convertPx, setGenderStyle, color, isRTL } }) => css`
    left: ${isRTL ? 'unset' : convertPx(-1)};
    right: ${isRTL ? convertPx(-1) : 'unset'};
    top: 0;
    bottom: 0;
    width: ${convertPx(1)};
    background-color: #414142;
    ${color &&
    color === 'budapestGirlBlue' &&
    css`
      background: #bcbec0;
    `}
    ${theme.accentTitleColors[color] &&
    css`
      background: ${theme.accentTitleColors[color]} !important;
    `}
  `}
`;

const BlockNestedItemLeft = styled(BlockNestedItem)`
  display: block;
  page-break-after: auto;
`;

const TextCont = styled.div`
  break-inside: auto;
  padding-top: ${({ theme }) => theme.convertPx(8)};
`;

const LeftSide = styled(View)`
  display: table-cell;
  ${({ theme: { getPaddingMargin, isRTL }, shorterList }) => css`
    padding: ${getPaddingMargin({ right: 25.5, bottom: 10 })};
    page-break-inside: avoid !important;
    break-inside: avoid !important;
    width: 25%;
    ${shorterList &&
    css`
      padding: ${getPaddingMargin({ right: 10, bottom: 10 })};
    `}
    ${isRTL &&
    css`
      padding: ${getPaddingMargin({ left: 10, bottom: 15 })};
    `}
  `}
`;

const RightSide = styled(View)`
  width: 65%;
  display: table-cell;
  ${({ theme: { convertPx }, $index, last, shorterList }) => css`
    padding-top: ${convertPx(30)};
    ${shorterList &&
    css`
      padding-top: ${convertPx(10)};
    `}
    ${$index === 0 &&
    css`
      padding-top: 0;
    `}
  `}
`;

const CityText = styled(Text)`
  font-size: ${({ theme }) => theme.convertPx(11, true)};
  text-transform: capitalize;
`;

const LeftSubHead = styled(SubHeader)`
  color: var(--light-values-white);
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.convertPx(14, true)};
  ${({ theme }) =>
    theme.color &&
    theme.color === 'budapestGirlBlue' &&
    css`
      color: #bcbec0;
    `}
`;

const StyledText = styled(Text)`
  page-break-inside: auto !important;
  break-inside: auto !important;
  color: #e6e7e8;
  ${({ theme }) =>
    theme.color &&
    theme.color === 'budapestGirlBlue' &&
    css`
      color: #bcbec0;
    `}
`;

export default RangeActivityDisplay;
