import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';
import { useResponsive } from 'imports/core/api/responsiveContext';

export const RenderEducationExperienceDnd = (props) => {
  const { isMobile } = useResponsive();
  if (isMobile) return props.children;
  return (
    <MarvelExperiment name="exp_education_experience_dnd">
      <MarvelVariant name="control">{props.children}</MarvelVariant>
      <MarvelVariant name="with_dnd">{props.children}</MarvelVariant>
    </MarvelExperiment>
  );
};
