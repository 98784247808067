import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import TextAtom from '/imports/pdf/budapest/ui/atoms/Text';
import EditRedirectionWrapper from '/imports/pdf/core/ui/components/EditRedirectionWrapper';
import { getClickToEditProps } from '/imports/pdf/core/api/helpers';
import { isSkillsTagsWithMoreSpaceActive } from 'imports/generator/api/helpers';

const TagDisplay = ({ title, left, item }) => (
  <EditRedirectionWrapper
    {...getClickToEditProps(item.id, { skill: true })}
    wrapperProps={{
      $notFullWidth: true,
      $styles: css`
        margin: ${({ theme }) =>
          `${theme.convertPx(6, false, 'px', true)} ${theme.convertPx(6, false, 'px', true)} 0px 0px `};
        page-break-inside: avoid;
        break-inside: avoid;
        display: block;
      `,
    }}
  >
    <Text left={left} className={'skills-item-tag'} data-id={item.id}>
      {title}
    </Text>
  </EditRedirectionWrapper>
);

TagDisplay.PropTypes = {
  title: PropTypes.string,
  left: PropTypes.bool,
  item: PropTypes.object,
};

const Text = styled(TextAtom)`
  border-radius: 2px;
  display: block;
  width: fit-content;
  page-break-inside: avoid !important;
  break-inside: avoid !important;
  line-height: ${({ theme }) => `${theme.convertPx(16, false, 'px', true)}`};
  padding: ${({ theme }) => `${theme.convertPx(6, false, 'px', true)} ${theme.convertPx(12)}`};
  font-size: ${({ theme }) => `${theme.convertPx(11, true)}`};
  ${({ theme }) => css`
    ${isSkillsTagsWithMoreSpaceActive(theme) &&
    css`
      padding: ${({ theme }) => `${theme.convertPx(5, false, 'px', true)} ${theme.convertPx(5)} !important`};
      font-size: ${({ theme }) => `${theme.convertPx(10, true)}`};
    `}
  `}
  text-transform: uppercase;
  color: #636466;
  border: ${({ theme }) => theme.convertPx(1)} solid #bbbbbb;
  ${({ left, theme: { setGenderStyle, color } }) =>
    left &&
    css`
      color: var(--light-values-white);
      ${color === 'budapestGirlBlue' &&
      css`
        color: #bcbec0;
      `}
    `}

  ${({ theme: { setGenderStyle, color } }) =>
    color === 'budapestGirlBlue' &&
    css`
      border: ${({ theme }) => theme.convertPx(1)} solid #a28828;
    `}

  ${({ theme: { color }, theme }) =>
    color &&
    color !== 'black' &&
    css`
      border: ${({ theme }) => `${theme.convertPx(1)} solid ${theme.colors[color]}`};
      ${theme.accentTitleColors[color] &&
      css`
        border-color: ${theme.accentTitleColors[color]} !important;
      `}
    `}

  ${({ left, theme: { setGenderStyle, color } }) =>
    left &&
    css`
      border: ${({ theme }) => theme.convertPx(1)} solid var(--light-values-white);
    `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 0;
    `}
`;

export default TagDisplay;
